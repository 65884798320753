.logedin_as {
  display: flex;
  color: #fff;
  align-items: center;
  margin-bottom: 10px;
}

.user_name {
  font-size: 14px;
}

.user_initial {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  background: #fff;
  display: flex;
  justify-content: center;
  color: #000;
  margin-left: 10px;
  margin-right: 10px;
}

.user_initial img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: solid black 1px;
}

.profile_logout {
  padding: 16px;
  width: 240px;
  box-sizing: border-box;
  text-align: left;
  background: #fff;
}

.profile_logout .logedin_as {
  color: #000;

  font-weight: 600;
  justify-content: space-between;
}

.profile_logout .logedin_as .user_name {
  font-size: 16px;
  line-height: 24px;
  font-family: "poppins";
  font-weight: normal;
  color: #000;
}

.profile_logout .user_initial {
  background: #000;
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 24px;
  margin-right: 0px;
}

.profile_logout .user_type {
  font-size: 14px;
  color: #888;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 159px;
  font-family: "poppins";
  font-weight: normal;
}

.profile_logout_links {
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: "poppins";
  font-weight: normal;
  color: #000;
}

.profile_logout_links button {
  cursor: pointer;
  background-color: #fff;
  border: none;
  color: #000000;
  display: flex;
  align-items: center;
  margin: 0px !important;
  padding: 0px !important;
}

.profile_logout_links button svg {
  margin-right: 4px;
}

.user_img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.user_img img {
  width: 100%;
}
.logout_button {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #222;
  padding: 0px;
  cursor: pointer;
}
.logout_button svg {
  margin-right: 6px;
}

.buttons_container {
  display: flex;
  justify-content: space-between;
}
