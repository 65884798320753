.container {
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  padding: 5px;
  border-left: solid silver 1px;
}

.help_frame {
  position: fixed;
  top: 40px;
  left: 0px;
  z-index: 999999;
  height: calc(100vh - 40px);
  width: 100vw;
  background-color: #fff;
  display: inline-block;
  border: none;
}

.hidden {
  display: none;
}