@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.Menu_Wrap {
  width: 320px;
  height: 465px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 10px;
  padding: 15px;
  position: fixed;
  z-index: 9999;
}

.menu {
  display: flex;
  justify-content: space-between;
}

.svg_sec {
  display: flex;
}

.dasboard_svg {
  margin-left: 14px;
}

/* search box css */
.menu_search_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  background: #ffffff;
  opacity: 0.4;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
}

.menu_search_box .inputBox {
  width: 180px;
  margin-right: 20px;
}

.inputBox .lbl {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #5fff618a;
}

.inputBox input {
  width: 100%;
  outline: none;
  background: #fff;
  height: 40px;
  box-sizing: border-box;
  padding: 0px 15px;
  font-size: 14px;
  border: none;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.8;
}

::placeholder {
  color: #53567e;
}

.inputBox select {
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  background: #222;
  height: 35px;
  box-sizing: border-box;
  padding: 0px 5px;
  font-size: 14px;
  font-weight: 500;
}

.search_icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.8;
}

/* padingation css */
.pagination_wrap {
  margin-top: 20px;
}

.menu_grid_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.grid_wrap {
  display: flex;
  margin-bottom: 16px;
}

.grid_name {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 12px;
}

.svg_sec {
  cursor: pointer;
}

.close_svg {
  cursor: pointer;
}

.bg_orange {
  background: linear-gradient(0deg, #fe763c, #fe763c), #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.bg_pink {
  background: linear-gradient(0deg, #ff2c91, #ff2c91), #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.bg_purple {
  background: linear-gradient(0deg, #9f38f0, #9f38f0), #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.bg_grey {
  background: linear-gradient(0deg, #85b5e4, #85b5e4), #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.bg_blue {
  background: linear-gradient(0deg, #4e5ae2, #4e5ae2), #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.bg_navy {
  background: linear-gradient(0deg, #0f234f, #0f234f), #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

/* ----------- top band ------------- */

.app_band {
  display: flex;
  height: 40px;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  z-index: 100000;
  position: sticky;
  top: 0px;
  width: 100%;
  box-sizing: border-box;
}

.logo_txt {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
}

.right_Sec {
  font-size: 20px;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.user_name {
  margin-right: 8px;
  cursor: pointer;
}

.user_text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-family: "poppins" !important;
  text-align: right;
  text-transform: capitalize;
}

.circle {
  background: #ffffff;
  border-radius: 24px;
  margin: 0px 5px;
}

.circle_text {
  background: #dfd5d5;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
}

.circle_text img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.menu_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
}

.legend {
  width: 18px;
  background: #fe763c !important;
  border-radius: 2px;
  height: 18px;
  margin-left: 16px;
}

.page_text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins" !important;
  line-height: 18px;
  text-align: center;
  color: var(--topband-app-color, #000) !important;
  text-decoration: none;
  padding: 3px 5px;
  border-radius: 3px;
  margin: 0px 3px;
  transition: all linear 0.2s;
}

.app_band:hover .page_text {
  color: #ffffff !important;
  background-color: var(--topband-app-color, #000) !important;

}

/* menu css */

.profile_logout_wrap {
  position: absolute;
  right: 0px;
  top: 0px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.hover_Wrap {
  margin-right: 3px;
  display: flex;
}


.menu_svg svg,
.menu_svg img {
  display: flex;
  align-items: center;
}

.hover_Wrap .home svg rect {
  fill: var(--topband-app-color, #000) !important;

}

.hover_Wrap .home {
  color: #fff;
  height: 26px;
  width: 26px;
  font-size: 13px;
  border-radius: 50%;
  display: inline;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-family: "poppins";
  font-weight: normal;
  margin-left: -35px;
  transition: margin-left linear 0.2s;
}

.app_band:hover .home {
  margin-left: 0px;
}

.menu_icon {
  margin-top: 0px;
  cursor: pointer;
}

.client_logo {
  margin-left: 5px;
  max-height: 28px;
  width: auto;
}


/* New Logo & App Name styles */

.j36_app{
display: flex;
font-family: "Poppins";
margin-left: 10px;
}
.j36_app .j36_logo{
background: #222;
color: #fff;
height: 24px;
min-width: 40px;
display: flex;
justify-content: center;
align-items: center;
border-radius:4px;
font-size: 14px;
font-weight: 700;
cursor: pointer;
letter-spacing: 0.5px;
}
.j36_logo span{
height: 18px;
}
.j36_logo svg{
vertical-align: baseline !important;
}
.j36_app .j36_logo_app{
border-radius: 4px 0px 0px 4px;
}
.j36_logo_hover{
display: none;
}
.j36_logo:hover .j36_logo_hover{
display: block;
}
.j36_logo:hover .j36_logo_default{
display: none;
}



.app_name_link{
color: #fff !important;
height: 24px;
line-height: 24px;
min-width: 40px;
display: flex;
justify-content: start;
align-items: center;
border-radius: 0px 4px 4px 0px;
font-size: 14px;
font-weight: 500;
text-transform: uppercase;
padding: 0px 8px;
cursor: pointer;
text-decoration: none;
transition: ease 0.5s;
flex-direction: column;
overflow: hidden;
}
.app_name_link .txt_01, .app_name_link .txt_02{
color: #fff !important;
}
.app_name_link .txt_02{
margin-bottom: -24px;
transition: ease 0.5s;

}
.app_name_link .txt_01{
margin-top:0px;
transition: ease 0.5s;
  
}
.app_name_link:hover .txt_01{
margin-top: -24px;
transition: ease 0.5s;
  
}

.app_name_link:hover .txt_02{
margin-bottom: 0px;
transition: ease 0.5s;
  
}